import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Seo from "../components/utils/seo";

const Index = ({ data }) => {
  const [openingRosemary, setOpeningRosemary] = useState(false);
  const [openingRosalie, setOpeningRosalie] = useState(false);
  const [showRosemary, setShowRosemary] = useState(true);
  const [showRosalie, setShowRosalie] = useState(true);
  const [doOpenRosemary, setDoOpenRosemary] = useState(false);
  const [doOpenRosalie, setDoOpenRosalie] = useState(false);
  const [doRosalieFade, setDoRosalieFade] = useState(false);
  const [doRosemaryFade, setDoRosemaryFade] = useState(false);

  const rosemaryVariants = {
    container: {
      width: "auto",
      height: "auto",
      alignItems: "center",
      display: "flex",
      borderRadius: 0,
    },
    normal: {},
    faded: { opacity: 0, borderRadius: "9999px" },
    disappeared: {
      width: 0,
      padding: 0,
      margin: 0,
      height: 0,
      opacity: 0,
      gap: 0,
    },
    hover: {
      scale: [1, 1.05, 1],
    },
    open: {
      backgroundColor: "#fff",
      borderRadius: [
        "9999px",
        "2000px",
        "1000px",
        "500px",
        "250px",
        "100px",
        "50px",
        "25px",
        "10px",
        "5px",
        "2px",
        "1px",
        0,
      ],
      width: [
        "10vw",
        "20vw",
        "30vw",
        "40vw",
        "50vw",
        "60vw",
        "70vw",
        "80vw",
        "90vw",
        "100vw",
      ],
      height: [
        "5vh",
        "15vh",
        "25vh",
        "35vh",
        "45vh",
        "55vh",
        "65vh",
        "75vh",
        "85vh",
        "95vh",
        "100vh",
      ],
    },
  };

  const animationStepSpeed = 0.5;

  return (
    <>
      <Seo metaProperties={data.allContentfulSeoProperties.nodes[0]} />
      <div className="bg-zinc-700 absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center">
        <motion.div
          className="flex flex-col content-between justify-center flex md:flex-row"
          variants={rosemaryVariants}
          initial="container"
          layout
        >
          <AnimatePresence>
            {showRosemary && (
              <motion.div
                className="w-48 h-48 md:h-64 md:w-64 mx-8 my-16 flex items-center"
                exit="disappeared"
                transistion={{ duration: animationStepSpeed }}
                variants={rosemaryVariants}
                initial="normal"
                whileHover="hover"
                animate={
                  doOpenRosemary ? "open" : openingRosalie ? "faded" : "normal"
                }
                onTap={() => setOpeningRosemary(true)}
                onAnimationComplete={(variant) => {
                  if (variant === "open") navigate("/rosemarybraddy");
                  if (variant === "faded") setShowRosemary(false);
                  if (variant === "disappeared") setDoRosalieFade(true);
                }}
              >
                <AnimatePresence>
                  {!doRosemaryFade && (
                    <motion.div
                      exit="faded"
                      transition={{ duration: animationStepSpeed }}
                      variants={rosemaryVariants}
                      initial="normal"
                      className="flex flex-col gap-2 md:gap-7 items-center text-yellow-600 text-2xl md:text-4xl font-script"
                      onAnimationComplete={(variant) =>
                        variant === "faded" && setDoOpenRosemary(true)
                      }
                    >
                      <div>Rosemary Braddy</div>
                      <motion.div
                        variants={rosemaryVariants}
                        initial="normal"
                        whileHover="hover"
                      >
                        <GatsbyImage
                          image={getImage(
                            data.allContentfulAsset.nodes.find(
                              (node) =>
                                node.contentful_id === "5zWGfp7kS67Rbai99ABsXw"
                            )
                          )}
                          className="bg-white h-48 w-48 md:h-64 md:w-64 rounded-full overflow-hidden"
                        />
                      </motion.div>
                      <div>Mezzo-Soprano</div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {showRosalie && (
              <motion.div
                className="h-48 w-48 md:h-64 md:w-64 mx-8 my-16 flex items-center"
                exit="disappeared"
                transition={{ duration: animationStepSpeed }}
                variants={rosemaryVariants}
                initial="normal"
                whileHover="hover"
                animate={
                  doOpenRosalie ? "open" : openingRosemary ? "faded" : "normal"
                }
                onTap={() => setOpeningRosalie(true)}
                onAnimationComplete={(variant) => {
                  if (variant === "open") navigate("/rosaliebea");
                  if (variant === "faded") setShowRosalie(false);
                  if (variant === "disappeared") setDoRosemaryFade(true);
                }}
              >
                <AnimatePresence>
                  {!doRosalieFade && (
                    <motion.div
                      exit="faded"
                      transition={{ duration: animationStepSpeed }}
                      variants={rosemaryVariants}
                      initial="normal"
                      className="flex flex-col gap-2 md:gap-7 items-center text-yellow-600 text-2xl md:text-4xl font-script text-center"
                      onAnimationComplete={(variant) =>
                        variant === "faded" && setDoOpenRosalie(true)
                      }
                    >
                      <div>RB</div>
                      <motion.div
                        variants={rosemaryVariants}
                        initial="normal"
                        whileHover="hover"
                        transition={{ duration: animationStepSpeed }}
                      >
                        <GatsbyImage
                          image={getImage(
                            data.allContentfulAsset.nodes.find(
                              (node) =>
                                node.contentful_id === "7l8UVzWu182qBk3DOap4IO"
                            )
                          )}
                          className="bg-white h-48 w-48 md:h-64 md:w-64 rounded-full overflow-hidden"
                        />
                      </motion.div>
                      <div>Function Singer</div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    allContentfulAsset(
      filter: {
        contentful_id: {
          in: ["7l8UVzWu182qBk3DOap4IO", "5zWGfp7kS67Rbai99ABsXw"]
        }
      }
    ) {
      nodes {
        contentful_id
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    allContentfulSeoProperties {
      nodes {
        description {
          description
        }
        image {
          gatsbyImageData(layout: FIXED)
        }
        title
      }
    }
  }
`;
